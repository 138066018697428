@font-face {
  font-family: 'Athletics_M';
  src: url('./components/assets/fonts/athletics/Athletics-Medium.ttf')
    format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Athletics';
  src: url('./components/assets/fonts/athletics/Athletics-Light.ttf')
    format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('./components/assets/fonts/HelveticaNeueCyr/HelveticaNeueCyr-Medium.ttf')
    format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Athletics', sans-serif;
  box-sizing: border-box;
  overflow-x: hidden;
}

h1 {
  font-family: 'HelveticaNeueCyr', serif;
  font-weight: 700;
}
h2 {
  font-family: 'Athletics_M', serif;
  font-weight: 600;
}

code {
  font-family: monospace;
}
